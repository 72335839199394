import { createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { ElMessage } from 'element-plus';
import 'element-plus/es/components/message/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "depositRule",
  mixins: [mixins],
  components: {},
  data() {
    return {
      currentId: null,
      detailDrawer: false,
      columns: [{
        label: '服务区域',
        prop: 'areaName'
      }, {
        label: '状态',
        customRender: props => {
          if (this.$hasPermission('electronicFence_status')) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.status,
              "onUpdate:modelValue": $event => props.row.status = $event,
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.changeEnabled(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.status ? '使用中' : '已禁用';
          }
        }
      }],
      topButtons: [{
        name: '新增',
        code: 'electronicFence_add',
        type: 'primary',
        click: this.add,
        permissions: ['electronicFence_add']
      }],
      linkButtons: [{
        name: '修改',
        code: 'electronicFence_edit',
        click: this.edit,
        permissions: ['electronicFence_edit']
      }],
      searchItems: [{
        type: 'cascader',
        prop: 'areaId',
        label: '服务区域',
        props: {
          checkStrictly: true,
          emitPath: false,
          value: 'id',
          label: 'name',
          lazy: true,
          lazyLoad: (node, resolve) => {
            const {
              level
            } = node;
            if (level === 0) {
              this.$api.uac.area.list({}).then(res => {
                if (res.code === 200) {
                  resolve(res.data);
                } else {
                  resolve([]);
                }
              });
            } else {
              resolve([]);
            }
          }
        }
      }]
    };
  },
  methods: {
    changeEnabled(info, resolve, reject) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          return resolve(true);
        } else {
          ElMessage.error(res.message);
          return reject(new Error('Error'));
        }
      };
      if (info.status) {
        this.$api.business.electronicFence.disable({
          id: info.id
        }).then(callBack).finally(() => this.switchLoading = false);
      } else {
        this.$api.business.electronicFence.enable({
          id: info.id
        }).then(callBack).finally(() => this.switchLoading = false);
      }
    },
    add() {
      this.$router.push('/electronicFenceAdd');
    },
    edit(row) {
      this.$router.push({
        path: '/electronicFenceAdd',
        query: {
          id: row.id
        }
      });
    }
  }
};